import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";

import Home from "./Home";
import Chat from "./familleProduits";
import Calendar from "./Calendar";

function Index() {
  const navigate = useNavigate();

  const handleLogout = () => {
    fetch("https://fastapiimg.onrender.com/api/v1/deconnexion/", {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`, 
        "Content-Type": "application/json",
      },
      credentials: "include", // Inclure si le serveur supporte les credentials
    })
      .then((response) => {
        if (response.ok || response.status === 0) { // Accepter aussi les réponses bloquées
          // Supprimer le token d'accès après la déconnexion
          localStorage.removeItem("accessToken");
          
          // Forcer la redirection vers la page d'authentification
          window.location.href = "/";
        } else {
          console.error("Erreur lors de la déconnexion.");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de l'appel à l'API de déconnexion:", error);
        
        // En cas d'erreur, supprimer le token et rediriger de toute façon
        localStorage.removeItem("accessToken");
        window.location.href = "/";
      });
  };
  

  const [hoveredItem, setHoveredItem] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    JSON.parse(localStorage.getItem("phoenixIsNavbarVerticalCollapsed")) ||
      false
  );

  // Fonction pour gérer l'ouverture et la fermeture de la sidebar
  const toggleSidebar = () => {
    const newCollapsedState = !isSidebarCollapsed;
    setIsSidebarCollapsed(newCollapsedState);
    localStorage.setItem("phoenixIsNavbarVerticalCollapsed", newCollapsedState);

    if (newCollapsedState) {
      document.documentElement.classList.add("navbar-vertical-collapsed");
    } else {
      document.documentElement.classList.remove("navbar-vertical-collapsed");
    }
  };

  // Utiliser useEffect pour synchroniser l'état de la sidebar lors du chargement
  useEffect(() => {
    if (isSidebarCollapsed) {
      document.documentElement.classList.add("navbar-vertical-collapsed");
    } else {
      document.documentElement.classList.remove("navbar-vertical-collapsed");
    }
  }, [isSidebarCollapsed]);

  // Styles pour l'état par défaut et l'état de survol
  const navItemStyle = {
    fontSize: "18px",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: "#ffa300", // Couleur du texte par défaut
  };

  const navItemHoverStyle = {
    backgroundColor: "white", // Couleur de fond au survol
    color: "#ffa300", // Couleur du texte au survol
  };
  return (
    <>
      <nav className="navbar navbar-vertical navbar-expand-lg">
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
          <div className="navbar-vertical-content">
            <ul className="navbar-nav flex-column" id="navbarVerticalNav">
              <li
                className="nav-item"
                style={{
                  color: "#139931", // Vert clair
                }}
                onMouseEnter={() => setHoveredItem("home")}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <div className="nav-item-wrapper">
                  <Link
                    className="nav-link label-1"
                    to="/home"
                    role="button"
                    data-bs-toggle
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        {/* Icon replaced with Boxicons */}
                        <i
                          className="bx bx-home-alt"
                          style={{ color: "#139931" }}
                        ></i>
                      </span>
                      <span className="nav-link-text-wrapper">
                        <span
                          className="nav-link-text"
                          style={{ color: "#139931" }}
                        >
                          Tableau de bord
                        </span>
                      </span>
                    </div>
                  </Link>
                </div>
              </li>
              <li
                className="nav-item"
                style={{ color: "#139931" }}
                onMouseEnter={() => setHoveredItem("home")}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <div className="nav-item-wrapper">
                  <Link
                    className="nav-link dropdown-indicator label-1"
                    to="#nv-home"
                    role="button"
                    data-bs-toggle="collapse"
                    aria-expanded="true"
                    aria-controls="nv-home"
                  >
                    <div className="d-flex align-items-center">
                      <div className="dropdown-indicator-icon-wrapper">
                        <span className="fas fa-caret-right dropdown-indicator-icon" />
                      </div>
                      <span className="nav-link-icon">
                        {/* Icon replaced with Boxicons */}
                        <i
                          className="bx bx-cog"
                          style={{ color: "#139931" }}
                        ></i>
                      </span>
                      <span
                        className="nav-link-text"
                        style={{ color: "#139931" }}
                      >
                        Paramétrage
                      </span>
                    </div>
                  </Link>
                  <div className="parent-wrapper label-1">
                    <ul
                      className="nav collapse parent"
                      data-bs-parent="#navbarVerticalCollapse"
                      id="nv-home"
                    >
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/produit">
                          <div className="d-flex align-items-center">
                            <span className="chevron-icon">
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Produits
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/famille">
                          <div className="d-flex align-items-center">
                            <span className="chevron-icon">
                              <i
                                className="bx bx-chevron-right"
                                style={{ color: "#139931" }}
                              ></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Familles de produits
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/collecteur">
                          <div className="d-flex align-items-center">
                            <span className="chevron-icon">
                              <i
                                className="bx bx-chevron-right"
                                style={{ color: "#139931" }}
                              ></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Collecteur
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/forme">
                          <div className="d-flex align-items-center">
                            <span className="chevron-icon">
                              <i
                                className="bx bx-chevron-right"
                                style={{ color: "#139931" }}
                              ></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Forme de produits
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/origine">
                          <div className="d-flex align-items-center">
                            <span className="chevron-icon">
                              <i
                                className="bx bx-chevron-right"
                                style={{ color: "#139931" }}
                              ></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Origine des produits
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/categorie">
                          <div className="d-flex align-items-center">
                            <span className="chevron-icon">
                              <i
                                className="bx bx-chevron-right"
                                style={{ color: "#139931" }}
                              ></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Catégorie Produits
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/regionNaturelles">
                          <div className="d-flex align-items-center">
                            <span
                              className="chevron-icon"
                              style={{ color: "#139931" }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Regions Naturelles
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/region">
                          <div className="d-flex align-items-center">
                            <span
                              className="chevron-icon"
                              style={{ color: "#139931" }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Regions
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/prefecture">
                          <div className="d-flex align-items-center">
                            <span className="chevron-icon">
                              <i
                                className="bx bx-chevron-right"
                                style={{ color: "#139931" }}
                              ></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Prefecture
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/commune">
                          <div className="d-flex align-items-center">
                            <span
                              className="chevron-icon"
                              style={{ color: "#139931" }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Commune
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/magasin">
                          <div className="d-flex align-items-center">
                            <span
                              className="chevron-icon"
                              style={{ color: "#139931" }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Magasin
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/marche">
                          <div className="d-flex align-items-center">
                            <span
                              className="chevron-icon"
                              style={{ color: "#139931" }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Marche
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/debarcadere">
                          <div className="d-flex align-items-center">
                            <span
                              className="chevron-icon"
                              style={{ color: "#139931" }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Debarcadere
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/unite">
                          <div className="d-flex align-items-center">
                            <span
                              className="chevron-icon"
                              style={{ color: "#139931" }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Unite de mesure
                            </span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li
                className="nav-item"
                style={{ color: "#139931" }}
                onMouseEnter={() => setHoveredItem("home")}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <div className="nav-item-wrapper">
                  <Link
                    className="nav-link dropdown-indicator label-1"
                    to="#nv-fiche"
                    role="button"
                    data-bs-toggle="collapse"
                    aria-expanded="true"
                    aria-controls="nv-fiche"
                  >
                    <div className="d-flex align-items-center">
                      <div className="dropdown-indicator-icon-wrapper">
                        <span className="fas fa-caret-right dropdown-indicator-icon" />
                      </div>
                      <span className="nav-link-icon">
                        {/* Icon replaced with Boxicons */}
                        <i
                          className="bx bx-book"
                          style={{ color: "#139931" }}
                        ></i>
                      </span>
                      <span
                        className="nav-link-text"
                        style={{ color: "#139931" }}
                      >
                        Fiche
                      </span>
                    </div>
                  </Link>
                  <div className="parent-wrapper label-1">
                    <ul
                      className="nav collapse parent"
                      data-bs-parent="#navbarVerticalCollapse"
                      id="nv-fiche"
                    >
                      <li
                        className="nav-item"
                        style={{ color: "#139931" }}
                        onMouseEnter={() => setHoveredItem("home")}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <Link className="nav-link" to="/grossiste">
                          <div className="d-flex align-items-center">
                            <span
                              className="chevron-icon"
                              style={{ color: "#139931" }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </span>
                            <span
                              className="nav-link-text"
                              style={{ color: "#139931" }}
                            >
                              Grossistes
                            </span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              {/* Add other sections like Chat or Calendar with corresponding Boxicons */}
            </ul>
          </div>
        </div>
        <div className="navbar-vertical-footer">
          <button
            className="btn navbar-vertical-toggle border-0 fw-semibold w-100 white-space-nowrap d-flex align-items-center"
            onClick={toggleSidebar}
          >
            <i
              className={`bx ${
                isSidebarCollapsed ? "bx-right-arrow-alt" : "bx-left-arrow-alt"
              } fs-8`}
            />
            <span className="navbar-vertical-footer-text ms-2">
              {isSidebarCollapsed ? "Vue étendue" : "Vue réduite"}
            </span>
          </button>
        </div>
      </nav>

      <nav
        className="navbar navbar-top fixed-top navbar-expand"
        style={{
          backgroundImage:
            "linear-gradient(to right, #ffff, #ffff, #ffef7a, #00a600)",
          borderBottom: "1px solid rgb(54, 159, 74)",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
          borderRadius: "0px 0px 30px 0px",
        }}
      >
        <div className="collapse navbar-collapse justify-content-between">
          <div className="navbar-logo">
            <button
              className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarVerticalCollapse"
              aria-controls="navbarVerticalCollapse"
              aria-expanded="false"
              aria-label="Toggle Navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line" />
              </span>
            </button>
            <a className="navbar-brand me-1 me-sm-3" href="index.html">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/img/icons/logo-simro.png"
                    alt="SIMRO"
                    width={67}
                    height={"100%"}
                  />
                  <h5 className="logo-text ms-2 d-none d-sm-block">
                    Système d'Information sur les Marchés du Riz et de l'Oignon
                    au Cameroun
                  </h5>
                </div>
              </div>
            </a>
          </div>
          <ul className="navbar-nav navbar-nav-icons flex-row">
            <li className="nav-item dropdown">
              <a
                className="nav-link lh-1 pe-0"
                id="navbarDropdownUser"
                href="#!"
                role="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="avatar avatar-l ">
                  <img
                    className="rounded-circle "
                    src="assets/img/team/72x72/user.png"
                    alt
                  />
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border"
                aria-labelledby="navbarDropdownUser"
              >
                <div className="card position-relative border-0">
                  <div className="card-body p-0">
                    <div className="text-center pt-4 pb-3">
                      <div className="avatar avatar-xl ">
                        <img
                          className="rounded-circle"
                          src="assets/img/team/72x72/user.png"
                          alt="user"
                        />
                      </div>
                      <h6 className="mt-2">Admin</h6>
                    </div>
                  </div>
                </div>
                <div className="card-footer p-0 border-0">
                  <ul className="nav d-flex flex-column my-3">
                    <li className="nav-item">
                      <Link className="nav-link px-3" to="/profile">
                        <i className="bx bx-user me-2" />
                        Profile & account
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link px-3" to="/settings">
                        <i className="bx bx-cog me-2" />
                        Settings
                      </Link>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link px-3" onClick={handleLogout}>
                        <i className="bx bx-power-off me-2" />
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Index;
