import React from "react";
import Index from "./index";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "ldrs/ring";
import { Alert } from "@mui/material";
import { hourglass } from "ldrs";
import { Modal, Button } from "react-bootstrap";
function Region() {
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState([]);
  const [regionNaturelles, setRegionNaturelles] = useState([]);
  const [codeRegion, setCodeRegion] = useState("");
  const [nomRegion, setNomRegion] = useState("");
  const [abregeRegion, setAbregeRegion] = useState("");
  const [couleurRegion, setCouleurRegion] = useState("");
  const [regionId, setRegionId] = useState(null); // Utilisé pour l'ID de la region
  const [showModal, setShowModal] = useState(false);
  const [selectedRegionNaturelles, setselectedRegionNaturelles] = useState("");
  const [selectedRegion, setselectedRegion] = useState(null); // Pour la region à modifier
  const [deleteMessage, setDeleteMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Message de succès
  const [errorMessage, setErrorMessage] = useState("");

  // Charger les données existantes
  useEffect(() => {
    fetch("https://fastapiimg.onrender.com/api/v1/all-region/")
      .then((response) => response.json())
      .then((data) => setRegion(data))
      .catch((error) =>
        console.error("Erreur lors du chargement des données:", error)
      );
  }, []);

  useEffect(() => {
    fetch("https://fastapiimg.onrender.com/api/v1/all-region-naturelle/")
      .then((response) => response.json())
      .then((data) => setRegionNaturelles(data))
      .catch((error) =>
        console.error("Erreur lors du chargement des familles produits:", error)
      );
  }, []);

  // Simuler le chargement
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Ouvrir et fermer le modal
  const handleShow = (region = null) => {
    if (region) {
      setselectedRegion(region); // Remplir les champs pour modification
      setRegionId(region.id_region); // Capturer l'ID pour la modification
      setCodeRegion(region.code_region);
      setNomRegion(region.nom_region);
      setAbregeRegion(region.abrege_region);
      setCouleurRegion(region.couleur);
      setselectedRegionNaturelles(region.region_naturelle);
    } else {
      setselectedRegion(null); // Pour un nouvel ajout
      setRegionId(null); // Aucun ID pour un nouvel ajout
      setCodeRegion("");
      setNomRegion("");
      setAbregeRegion("");
      setCouleurRegion("");
      setselectedRegionNaturelles(null);
    }
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  // Gérer le changement des inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "codeRegion") {
      setCodeRegion(value);
    } else if (name === "nomRegion") {
      setNomRegion(value);
    } else if (name === "regionNaturelles") {
      setRegionNaturelles(value); // Mettre à jour la famille sélectionnée
    } else if (name === "abreviation") {
      setAbregeRegion(value);
    } else if (name === "couleur") {
      setCouleurRegion(value);
    }
  };

  // Fonction pour ajouter ou modifier une region de produit
  const soumettreregionProduit = () => {
    const regionData = {
      id_region: regionId, // Utiliser l'ID ici pour la modification
      code_region: codeRegion,
      nom_region: nomRegion,
      region_naturelle: selectedRegionNaturelles,
      couleur: couleurRegion,
      abrege_region: abregeRegion, // Si le checkbox est coché, mettre 1, sinon 0
      id_personnel: "", // Remplacez par la valeur appropriée
      etat: "actif", // Un état par défaut
      date_enregistrement: selectedRegion
        ? selectedRegion.date_enregistrement
        : new Date().toISOString().split("T")[0],
      modifier_le: new Date().toISOString().split("T")[0],
      modifier_par: "admin", // Remplacez par la personne qui modifie
      layoutId: 0,
      ownerId: 0,
      name: null,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      zIndex: 0,
      duration: 0,
    };

    const url = selectedRegion
      ? `https://fastapiimg.onrender.com/api/v1/region/update/${regionId}/` // Utiliser l'ID ici
      : "https://fastapiimg.onrender.com/api/v1/region/create/";

    const method = selectedRegion ? "PUT" : "POST";

    fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(regionData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (selectedRegion) {
          // Mise à jour de la region dans la liste
          setRegion(
            region.map((f) => (f.id_region === data.id_region ? data : f))
          );
        } else {
          // Ajouter une nouvelle region à la liste
          setRegion([...region, data]);
        }
        // Réinitialiser les champs
        // Fermer le modal après soumission
        setSuccessMessage(
          selectedRegion ? "Modification réussie !" : "Ajout réussi !"
        );
        setErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);

        // Réinitialiser le formulaire et fermer le modal
        setRegionId(null); // Réinitialiser l'ID après la soumission
        setCodeRegion("");
        setNomRegion("");
        setAbregeRegion("");
        setCouleurRegion("");
        setselectedRegionNaturelles(null);
        setShowModal(false);
      })
      .catch((error) => {
        // Afficher le message d'erreur
        setErrorMessage("Échec de l'enregistrement.");
        setSuccessMessage("");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        console.error("Erreur lors de l'ajout ou de la modification:", error);
      });
  };
  const supprimerregionProduit = (id) => {
    fetch(`https://fastapiimg.onrender.com/api/v1/region/delete/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          // Supprimer la region localement après succès
          setRegion(region.filter((region) => region.id_region !== id));
          setDeleteMessage("Suppression réussie !");
          setTimeout(() => {
            setDeleteMessage(""); // Masquer le message après 3 secondes
          }, 3000);
        } else {
          console.error(
            "Erreur lors de la suppression de la région naturelle:",
            response
          );
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la suppression de la région naturelle:",
          error
        );
      });
  };

  // Gérer le clic sur "Supprimer"
  const handleDeleteClick = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cette region de produits ?"
      )
    ) {
      supprimerregionProduit(id);
    }
  };
  // Ajouter ces états pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Nombre d'éléments par page

  // Calculer les indices des éléments à afficher
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = region.slice(indexOfFirstItem, indexOfLastItem);

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Nombre total de pages
  const totalPages = Math.ceil(region.length / itemsPerPage);
  console.log(regionNaturelles);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // occupe toute la hauteur de la page
            backgroundImage: "assets/img/icons/logo-simro.png",
          }}
        >
          <l-hourglass
            color={"#00a600"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          ></l-hourglass>
        </div>
      ) : (
        <>
          <Index />
          <div className="content">
            <h3
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                color: "gold",
              }}
            >
              Liste des regions
            </h3>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
            {deleteMessage && (
              <Alert severity="info" sx={{ mb: 2 }}>
                {deleteMessage}
              </Alert>
            )}
            <div
              id="tableExample3"
              data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div class="search-box mb-3">
                  <form className="position-relative">
                    <input
                      className="form-control search-input search form-control-sm"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <svg
                      className="svg-inline--fa fa-magnifying-glass search-box-icon"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="magnifying-glass"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      />
                    </svg>
                    {/* <span class="fas fa-search search-box-icon"></span> Font Awesome fontawesome.com */}
                  </form>
                </div>
                <div>
                  <button
                    id="addBtn"
                    className="btn btn-primary"
                    onClick={() => handleShow()}
                    style={{
                      backgroundColor: "#00a600",
                    }}
                  >
                    <svg
                      className="svg-inline--fa fa-plus me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="plus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      ></path>
                    </svg>
                    Nouvelle region
                  </button>
                </div>
              </div>
              <div
                className="table-responsive"
                style={{
                  backgroundColor: "#f8f9fa",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 4px 8px green",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <table
                  className="table table-striped table-sm fs-9 mb-0"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    borderSpacing: "0 10px",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          cursor: "pointer",
                          fontSize: "18px",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        Code region
                      </th>
                      <th
                        className="sort border-top border-translucent ps-3"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          cursor: "pointer",
                          fontSize: "18px",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        Nom de la region
                      </th>
                      <th
                        className="sort border-top border-translucent ps-3"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          cursor: "pointer",
                          fontSize: "18px",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        Abreviation de la region
                      </th>
                      <th
                        className="sort border-top border-translucent ps-3"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          cursor: "pointer",
                          fontSize: "18px",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        Region Naturelle
                      </th>
                      <th
                        className="sort border-top border-translucent ps-3"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          cursor: "pointer",
                          fontSize: "18px",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        Couleur
                      </th>
                      <th
                        className="sort text-end align-middle pe-0 border-top"
                        scope="col"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {currentItems.length > 0 ? (
                      currentItems.map((region, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {region.code_region}
                          </td>
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {region.nom_region}
                          </td>
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {region.abrege_region}
                          </td>
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {region.region_naturelle}
                          </td>
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              backgroundColor: region.couleur,
                            }}
                          >
                            {region.couleur}
                          </td>
                          <td className="align-middle white-space-nowrap text-end pe-0">
                            <div className="btn-reveal-trigger position-static">
                              <button
                                className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-boundary="window"
                                aria-haspopup="true"
                                aria-expanded="false"
                                data-bs-reference="parent"
                              >
                                <svg
                                  className="svg-inline--fa fa-ellipsis fs-10"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="ellipsis"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
                                  />
                                </svg>
                              </button>
                              <div className="dropdown-menu dropdown-menu-end py-2">
                                <a
                                  className="dropdown-item"
                                  onClick={() => handleShow(region)}
                                >
                                  Modifier
                                </a>
                                <div className="dropdown-divider" />
                                <a
                                  className="dropdown-item text-danger"
                                  onClick={() =>
                                    handleDeleteClick(region.id_region)
                                  }
                                >
                                  Supprimer
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Aucune forme trouvée.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <ul className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index + 1} className="page-item">
                      <button
                        onClick={() => paginate(index + 1)}
                        className={`page-link ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Modal d'ajout ou de modification */}
            {showModal && (
              <div
                className="modal fade show d-block"
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content centered border border-translucent">
                    <form
                      id="addFamilyForm"
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        soumettreregionProduit();
                      }}
                    >
                      <div className="modal-header px-card border-0">
                        <div className="w-100 d-flex justify-content-between align-items-start">
                          <div>
                            <h5 className="mb-0 lh-sm text-body-highlight">
                              {selectedRegion
                                ? "Modifier l'region du Produit"
                                : "Ajouter une region de Produits"}
                            </h5>
                          </div>
                          <button
                            type="button"
                            className="btn p-1 fs-10 text-body"
                            onClick={handleClose}
                          >
                            Annuler
                          </button>
                        </div>
                      </div>

                      <div className="modal-body p-card py-0">
                        {/* Code region Produit */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="codeRegion"
                            type="text"
                            name="codeRegion"
                            value={codeRegion}
                            onChange={handleInputChange}
                            required
                            placeholder="Code region Produit"
                          />
                          <label htmlFor="codeRegion">Code region</label>
                        </div>

                        {/* Nom region Produit */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="nomRegion"
                            type="text"
                            name="nomRegion"
                            value={nomRegion}
                            onChange={handleInputChange}
                            placeholder="Nom region Produit"
                            required
                          />
                          <label htmlFor="nomRegion">Nom region Produit</label>
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="abreviation"
                            type="text"
                            name="abreviation"
                            value={abregeRegion}
                            onChange={handleInputChange}
                            placeholder="Nom region Produit"
                            required
                          />
                          <label htmlFor="nomRegion">Abreviation</label>
                        </div>

                        <div className="form-floating mb-3">
                          <select
                            className="form-control"
                            id="regionNaturelles"
                            name="regionNaturelles"
                            value={selectedRegionNaturelles}
                            onChange={(e) =>
                              setselectedRegionNaturelles(e.target.value)
                            } // Met à jour la famille sélectionnée
                          >
                            {regionNaturelles && regionNaturelles.length > 0 ? (
                              regionNaturelles.map((region) => (
                                <option
                                  key={region.id_region_naturelle}
                                  value={region.id_region_naturelle}
                                >
                                  {region.nom_region_naturelle}
                                </option>
                              ))
                            ) : (
                              <option value="">
                                Sélectionnez une region Naturelle
                              </option>
                            )}
                          </select>

                          <label htmlFor="famillesProduits">
                            Region Naturelle
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="couleur"
                            type="text"
                            name="couleur"
                            value={couleurRegion}
                            onChange={handleInputChange}
                            required
                            placeholder="couleur region"
                          />
                          <label htmlFor="couleur">Couleur</label>
                        </div>
                      </div>

                      <div className="modal-footer d-flex justify-content-between align-items-center border-0">
                        <button
                          className="btn btn-primary px-4"
                          type="submit"
                          style={{
                            backgroundColor: "#00a600",
                          }}
                        >
                          {selectedRegion
                            ? "Modifier region"
                            : "Ajouter region"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Region;
