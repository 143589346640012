import Index from "./index";
import React from "react";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "ldrs/ring";
import { hourglass } from "ldrs";
import { FaUserAlt, FaBoxOpen, FaUserCheck,FaUserSecret } from "react-icons/fa";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [enquet, setEnquet] = useState(0);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  hourglass.register();

  useEffect(() => {
    // Récupération du nombre d'utilisateurs
    fetch("https://fastapiimg.onrender.com/api/v1/all/users/")
      .then((response) => response.json())
      .then((data) => {
        setUserCount(data.length); // Supposant que l'API renvoie un tableau d'utilisateurs
      })
      .catch((error) =>
        console.error(
          "Erreur lors de la récupération des utilisateurs :",
          error
        )
      );

      fetch("https://fastapiimg.onrender.com/api/v1/all/users/")
      .then((response) => response.json())
      .then((data) => {
        const activeUsers = data.filter((user) => user.is_active === 1);
        setActiveUserCount(activeUsers.length);
      })
      .catch((error) =>
        console.error("Erreur lors de la récupération des utilisateurs :", error)
      );

    // Récupération du nombre de produits
    fetch("https://fastapiimg.onrender.com/api/v1/all-produits/")
      .then((response) => response.json())
      .then((data) => {
        setProductCount(data.length); // Supposant que l'API renvoie un tableau de produits
      })
      .catch((error) =>
        console.error("Erreur lors de la récupération des produits :", error)
      );

  fetch("https://fastapiimg.onrender.com/api/v1/all-enqueteur/")
  .then((response) => response.json())
  .then((data) => {
    setEnquet(data.length); // Supposant que l'API renvoie un tableau de produits
  })
  .catch((error) =>
    console.error("Erreur lors de la récupération des produits :", error)
  );
}, []);
  

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // occupe toute la hauteur de la page
            backgroundImage: "assets/img/icons/logo-simro.png",
          }}
        >
          <l-hourglass
            color={"#00a600"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          ></l-hourglass>
        </div>
      ) : (
        <>
          <Index />
          <div className="content">
            <div className="pb-5">
              <div className="row g-4">
                <div className="col-12 col-xxl-6">
                  <div
                    className="mb-4"
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      backgroundColor: "#f5f7fa",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <h2
                      style={{
                        margin: "0",
                        fontSize: "2rem",
                        fontWeight: "700",
                        color: "green",
                        letterSpacing: "1px",
                        lineHeight: "1.2",
                      }}
                    >
                      Tableau de Bord
                    </h2>
                  </div>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "2px solid #dee2e6",
                      width: "80%",
                      margin: "20px auto 0",
                      opacity: "0.8",
                    }}
                  />
                </div>
                <div className="col-12 col-xxl-6">
                  <div className="row g-3">
                    {/* Card pour le nombre d'utilisateurs */}
                    <div className="col-12 col-md-6">
                      <div
                        className="card h-100"
                        style={{ boxShadow: "0 4px 8px green" }}
                      >
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5 className="mb-1" style={{ display: "flex", alignItems: "center" }}>
                                <FaUserAlt size={24} style={{ marginRight: "8px", color: "green" }} />
                                Comptes créés
                              </h5>
                              <h6 className="text-body-tertiary">
                                Total utilisateurs
                              </h6>
                            </div>
                            <h1 style={{ color: "#fc8e38"}}>{userCount}</h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Card pour le nombre de produits */}
                    <div className="col-12 col-md-6">
                      <div
                        className="card h-100"
                        style={{ boxShadow: "0 4px 8px green" }}
                      >
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5 className="mb-1" style={{ display: "flex", alignItems: "center" }}>
                                <FaBoxOpen size={24} style={{ marginRight: "8px", color: "green" }} />
                                Produits
                              </h5>
                              <h6 className="text-body-tertiary">
                                Total produits
                              </h6>
                            </div>
                            <h1 style={{ color: "#fc8e38"}}>{productCount}</h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Card pour le nombre d'utilisateurs actifs */}
                    <div className="col-12 col-md-6">
                      <div
                        className="card h-100"
                        style={{ boxShadow: "0 4px 8px green" }}
                      >
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5 className="mb-1" style={{ display: "flex", alignItems: "center" }}>
                                <FaUserCheck size={24} style={{ marginRight: "8px", color: "green" }} />
                                Comptes actifs
                              </h5>
                              <h6 className="text-body-tertiary">
                                Total utilisateurs actifs
                              </h6>
                            </div>
                            <h1 style={{ color: "#fc8e38"}}>{activeUserCount}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {/* Autre carte */}
                    <div className="col-12 col-md-6">
                      <div
                        className="card h-100"
                        style={{ boxShadow: "0 4px 8px green" }}
                      >
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5 className="mb-1" style={{ display: "flex", alignItems: "center" }}>
                                <FaUserSecret size={24} style={{ marginRight: "8px", color: "green" }} />
                                Enqueteurs
                              </h5>
                              <h6 className="text-body-tertiary">
                                Total enqueteurs
                              </h6>
                            </div>
                            <h1 style={{ color: "#fc8e38"}}>{enquet}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <footer className="footer position-absolute">
              <div className="row g-0 justify-content-between align-items-center h-100">
                <div className="col-12 col-sm-auto text-center">
                  <p className="mb-0 mt-2 mt-sm-0 text-body">
                  Tous droits Réservés à Simro
                    <span className="d-none d-sm-inline-block" />
                    <span className="d-none d-sm-inline-block mx-1">|</span>
                    <br className="d-sm-none" />
                    2024 ©
                    <a className="mx-1" href="https://simro.info/">
                      SIMRO
                    </a>
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
