import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Authentification = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // Renommage de la variable pour éviter les conflits
  const requestData = new URLSearchParams();
  requestData.append("username", username);
  requestData.append("password", password);
  requestData.append("grant_type", "password");
  requestData.append("scope", "");
  requestData.append("client_id", "string");
  requestData.append("client_secret", "string");

  const navigate = useNavigate(); // Initialiser useNavigate pour la redirection

  const handleLogin = async () => {
    try {
      // Remplacer l'URL par celle de ton API
      const response = await fetch(
        "https://fastapiimg.onrender.com/api/v1/user/login/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
          body: requestData.toString(), // Utilisation de requestData
        }
      );
  
      if (!response.ok) {
        throw new Error("Invalid username or password");
      }
  
      const responseData = await response.json(); // Renommage de data en responseData
  
      // Récupérer et stocker le token dans localStorage
      const accessToken = responseData.access_token;
      localStorage.setItem("token", accessToken); // Stocker le token
  
      // Rediriger vers la page Home après connexion réussie
      navigate("/home"); // <--- Redirection vers la page "home"
  
      console.log("Token:", accessToken); // Afficher le token dans la console
    } catch (error) {
      setError(error.message);
      alert(error.message); // Affiche une alerte d'erreur
    }
  };
  

  return (
    <div>
      <div>
        <header
          className="p-0.5 mb-0"
          style={{
            backgroundColor: "green",
            borderBottom: "1px solid rgb(54, 159, 74)",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            borderRadius: "0px 0px 30px 30px",
          }}
        >
          <h3 className="text-center m-0" style={{ color: "white" }}>
            <p className="mb-0">
              Bienvenue sur la plateforme de gestion de SIMRO
            </p>
          </h3>
          <h3
            className="mt-0"
            style={{
              color: "#d0c307",
              borderRadius: "40px 40px 40px 40px",
              textAlign: "center",
            }}
          >
            Système d'Information sur les Marchés du Riz et de l'Oignon au
            Cameroun
          </h3>
        </header>
        <main className="main" id="top">
          <div className="container-fluid bg-body-tertiary dark__bg-gray-1200">
            {/* <div class="bg-holder bg-auth-card-overlay" style="background-image:url(../../../assets/img/bg/37.png);"> */}
          </div>
          {/*/.bg-holder*/}
          <div className="row flex-center position-relative g-0 mt-0">
            <div className="col-11 col-sm-10 col-xl-8">
              <div className="card border border-translucent auth-card">
                <div className="card-body pe-md-0">
                  <div className="row align-items-center gx-0 gy-7">
                    {/* <h3 class="mb-3 text-body-emphasis fs-5"
          style="background-color: #2cb01b; border-radius: 10px; padding: 10px; color: white;"></h3> */}
                    <div className="col-auto bg-body-highlight dark__bg-gray-1100 rounded-3 position-relative overflow-hidden auth-title-box">
                      <div
                        className="bg-holder"
                        style={{
                          backgroundImage:
                            "url(../../../assets/img/bg/riz3.webp)",
                        }}
                      ></div>
                      <div className="position-relative z-n1 mb-6 d-none d-md-block text-center mt-md-15">
                        <img
                          className="auth-title-box-img d-dark-none"
                          src="../../../assets/img/spot-illustrations/auth.png"
                          alt
                        />
                        <img
                          className="auth-title-box-img d-light-none"
                          src="../../../assets/img/spot-illustrations/auth-dark.png"
                          alt
                        />
                      </div>
                    </div>
                    <div className="col mx-auto">
                      <div className="auth-form-box">
                        <div className="text-center mb-7">
                          <a
                            className="d-flex flex-center text-decoration-none mb-4"
                            href="../../../index.html"
                          >
                            <div className="d-flex align-items-center fw-bolder fs-3 d-inline-block">
                              <img
                                src="../../../assets/img/icons/logo-simro.png"
                                alt="phoenix"
                                width={88}
                              />
                            </div>
                          </a>
                          <h3 className="text-body-highlight">Connexion</h3>
                          <p className="text-body-tertiary">
                            Veuillez vous connecter pour accéder à votre compte
                          </p>
                        </div>
                        <div className="mb-3 text-start">
                          <label className="form-label" htmlFor="name">
                            Pseudo
                          </label>
                          <div className="form-icon-container">
                            <input
                              className="form-control form-icon-input"
                              id="email"
                              type="text"
                              placeholder="name223"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                            />
                            <span className="fas fa-user text-body fs-9 form-icon" />
                          </div>
                        </div>
                        <div className="mb-3 text-start">
                          <label className="form-label" htmlFor="password">
                            Mot de passe
                          </label>
                          <div
                            className="form-icon-container"
                            data-password="data-password"
                          >
                            <input
                              className="form-control form-icon-input pe-6"
                              id="password"
                              type="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="fas fa-key text-body fs-9 form-icon" />
                            <button
                              className="btn px-3 py-0 h-100 position-absolute top-0 end-0 fs-7 text-body-tertiary"
                              data-password-toggle="data-password-toggle"
                            >
                              <span className="uil uil-eye show" />
                              <span className="uil uil-eye-slash hide" />
                            </button>
                          </div>
                        </div>
                        <button
                          className="btn btn-primary w-100 mb-3"
                          style={{
                            backgroundColor: "#2cb01b",
                            borderColor: "#80ff00",
                          }}
                          onClick={handleLogin}
                        >
                          Connexion
                        </button>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Authentification;
