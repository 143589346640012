import React from "react";
import Index from "./index";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "ldrs/ring";
import { Alert } from "@mui/material";
import { hourglass } from "ldrs";
import { Modal, Button } from "react-bootstrap";
function CategorieProduits() {
  const [loading, setLoading] = useState(false);
  const [categorieProduits, setcategorieProduits] = useState([]);
  const [famillesProduits, setFamillesProduits] = useState([]);
  const [codecategorieProduit, setCodecategorieProduit] = useState("");
  const [nomcategorieProduit, setNomcategorieProduit] = useState("");
  const [categorieId, setcategorieId] = useState(null); // Utilisé pour l'ID de la categorie
  const [showModal, setShowModal] = useState(false);
  const [selectedFamille, setSelectedFamille] = useState("");
  const [selectedcategorie, setSelectedcategorie] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(""); 
  const [successMessage, setSuccessMessage] = useState(""); // Message de succès
  const [errorMessage, setErrorMessage] = useState(""); // Pour la categorie à modifier

  // Charger les données existantes
  useEffect(() => {
    fetch("https://fastapiimg.onrender.com/api/v1/all-categorie-produits/")
      .then((response) => response.json())
      .then((data) => setcategorieProduits(data))
      .catch((error) =>
        console.error("Erreur lors du chargement des données:", error)
      );
  }, []);

  useEffect(() => {
    fetch("https://fastapiimg.onrender.com/api/v1/all-famille-produits/")
      .then((response) => response.json())
      .then((data) => setFamillesProduits(data))
      .catch((error) =>
        console.error("Erreur lors du chargement des familles produits:", error)
      );
  }, []);

  // Simuler le chargement
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Ouvrir et fermer le modal
  const handleShow = (categorie = null) => {
    if (categorie) {
      setSelectedcategorie(categorie); // Remplir les champs pour modification
      setcategorieId(categorie.id_categorie_produit); // Capturer l'ID pour la modification
      setCodecategorieProduit(categorie.code_categorie_produit);
      setNomcategorieProduit(categorie.nom_categorie_produit);
      setSelectedFamille(categorie.famille_produits);
    } else {
      setSelectedcategorie(null); // Pour un nouvel ajout
      setcategorieId(null); // Aucun ID pour un nouvel ajout
      setCodecategorieProduit("");
      setNomcategorieProduit("");
      setSelectedFamille(null);
    }
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  // Gérer le changement des inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "codecategorieProduit") {
      setCodecategorieProduit(value);
    } else if (name === "nomcategorieProduit") {
      setNomcategorieProduit(value);
    } else if (name === "famillesProduits") {
      setFamillesProduits(value); // Mettre à jour la famille sélectionnée
    }
  };

  // Fonction pour ajouter ou modifier une categorie de produit
  const soumettrecategorieProduit = () => {
    const categorieData = {
      id_categorie_produit: categorieId, // Utiliser l'ID ici pour la modification
      code_categorie_produit: codecategorieProduit,
      nom_categorie_produit: nomcategorieProduit,
      famille_produit: selectedFamille,
    };

    const url = selectedcategorie
      ? `https://fastapiimg.onrender.com/api/v1/categorie-produit/update/${categorieId}/` // Utiliser l'ID ici
      : "https://fastapiimg.onrender.com/api/v1/categorie-produit/create/";

    const method = selectedcategorie ? "PUT" : "POST";

    fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(categorieData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (selectedcategorie) {
          // Mise à jour de la categorie dans la liste
          setcategorieProduits(
            categorieProduits.map((f) =>
              f.id_categorie_produit === data.id_categorie_produit ? data : f
            )
          );
        } else {
          // Ajouter une nouvelle categorie à la liste
          setcategorieProduits([...categorieProduits, data]);
        }
        setSuccessMessage(
          selectedcategorie ? "Modification réussie !" : "Ajout réussi !"
        );
        setErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);

        // Réinitialiser le formulaire et fermer le modal
        setcategorieId(null); // Réinitialiser l'ID après la soumission
        setCodecategorieProduit("");
        setNomcategorieProduit("");
        setSelectedFamille(null);
        setShowModal(false);
      })
      .catch((error) => {
        // Afficher le message d'erreur
        setErrorMessage("Échec de l'enregistrement.");
        setSuccessMessage("");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        console.error("Erreur lors de l'ajout ou de la modification:", error);
      });
  };
  const supprimercategorieProduit = (id) => {
    fetch(`https://fastapiimg.onrender.com/api/v1/categorie-produit/delete/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          // Supprimer la categorie localement après succès
          setcategorieProduits(
            categorieProduits.filter(
              (categorie) => categorie.id_categorie_produit !== id
            )
          );
          setDeleteMessage("Suppression réussie !");
          setTimeout(() => {
            setDeleteMessage(""); // Masquer le message après 3 secondes
          }, 3000);
        } else {
          console.error(
            "Erreur lors de la suppression de la région naturelle:",
            response
          );
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la suppression de la région naturelle:",
          error
        );
      });
  };

  // Gérer le clic sur "Supprimer"
  const handleDeleteClick = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cette categorie de produits ?"
      )
    ) {
      supprimercategorieProduit(id);
    }
  };
  // Ajouter ces états pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Nombre d'éléments par page

  // Calculer les indices des éléments à afficher
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categorieProduits.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Nombre total de pages
  const totalPages = Math.ceil(categorieProduits.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // occupe toute la hauteur de la page
            backgroundImage: "assets/img/icons/logo-simro.png",
          }}
        >
          <l-hourglass
            color={"#00a600"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          ></l-hourglass>
        </div>
      ) : (
        <>
          <Index />
          <div className="content">
            <h3
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                color: "gold",
              }}
            >
              Liste des categories de produits
            </h3>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
                        {deleteMessage && (
              <Alert severity="info" sx={{ mb: 2 }}>
                {deleteMessage}
              </Alert>
            )}
            <div
              id="tableExample3"
              data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div class="search-box mb-3">
                  <form className="position-relative">
                    <input
                      className="form-control search-input search form-control-sm"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <svg
                      className="svg-inline--fa fa-magnifying-glass search-box-icon"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="magnifying-glass"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      />
                    </svg>
                    {/* <span class="fas fa-search search-box-icon"></span> Font Awesome fontawesome.com */}
                  </form>
                </div>
                <div>
                  <button
                    id="addBtn"
                    className="btn btn-primary"
                    onClick={() => handleShow()}
                    style={{
                      backgroundColor: "#00a600",
                    }}
                  >
                    <svg
                      className="svg-inline--fa fa-plus me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="plus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      ></path>
                    </svg>
                    Nouvelle categorie de produit
                  </button>
                </div>
              </div>
              <div
                className="table-responsive"
                style={{
                  backgroundColor: "#f8f9fa",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 4px 8px green",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <table
                  className="table table-striped table-sm fs-9 mb-0"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    borderSpacing: "0 10px",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sort"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Code categorie de Produit
                      </th>
                      <th
                        className="sort"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Nom de la categorie de Produit
                      </th>
                      <th
                        className="sort"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Famille Produits
                      </th>
                      <th
                        className="sort"
                        scope="col"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "700",
                          cursor: "pointer",
                          fontSize: "18px",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {currentItems.length > 0 ? (
                      currentItems.map((categorie, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {categorie.code_categorie_produit}
                          </td>
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {categorie.nom_categorie_produit}
                          </td>
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {categorie.famille_produit}
                          </td>
                          <td className="align-middle white-space-nowrap text-end pe-0">
                            <div className="btn-reveal-trigger position-static">
                              <button
                                className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-boundary="window"
                                aria-haspopup="true"
                                aria-expanded="false"
                                data-bs-reference="parent"
                              >
                                <svg
                                  className="svg-inline--fa fa-ellipsis fs-10"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="ellipsis"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
                                  />
                                </svg>
                              </button>
                              <div className="dropdown-menu dropdown-menu-end py-2">
                                <a
                                  className="dropdown-item"
                                  onClick={() => handleShow(categorie)}
                                >
                                  Modifier
                                </a>
                                <div className="dropdown-divider" />
                                <a
                                  className="dropdown-item text-danger"
                                  onClick={() =>
                                    handleDeleteClick(
                                      categorie.id_categorie_produit
                                    )
                                  }
                                >
                                  Supprimer
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Aucune forme trouvée.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <ul className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index + 1} className="page-item">
                      <button
                        onClick={() => paginate(index + 1)}
                        className={`page-link ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Modal d'ajout ou de modification */}
            {showModal && (
              <div
                className="modal fade show d-block"
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content centered border border-translucent">
                    <form
                      id="addFamilyForm"
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        soumettrecategorieProduit();
                      }}
                    >
                      <div className="modal-header px-card border-0">
                        <div className="w-100 d-flex justify-content-between align-items-start">
                          <div>
                            <h5 className="mb-0 lh-sm text-body-highlight">
                              {selectedcategorie
                                ? "Modifier l'categorie du Produit"
                                : "Ajouter une categorie de Produits"}
                            </h5>
                          </div>
                          <button
                            type="button"
                            className="btn p-1 fs-10 text-body"
                            onClick={handleClose}
                          >
                            Annuler
                          </button>
                        </div>
                      </div>

                      <div className="modal-body p-card py-0">
                        {/* Code categorie Produit */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="codecategorieProduit"
                            type="text"
                            name="codecategorieProduit"
                            value={codecategorieProduit}
                            onChange={handleInputChange}
                            required
                            placeholder="Code categorie Produit"
                          />
                          <label htmlFor="codecategorieProduit">
                            Code categorie Produit
                          </label>
                        </div>

                        {/* Nom categorie Produit */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="nomcategorieProduit"
                            type="text"
                            name="nomcategorieProduit"
                            value={nomcategorieProduit}
                            onChange={handleInputChange}
                            required
                            placeholder="Nom categorie Produit"
                          />
                          <label htmlFor="nomcategorieProduit">
                            Nom categorie Produit
                          </label>
                        </div>

                        <div className="form-floating mb-3">
                          <select
                            className="form-control"
                            id="famillesProduits"
                            name="famillesProduits"
                            value={selectedFamille}
                            onChange={(e) => setSelectedFamille(e.target.value)} // Met à jour la famille sélectionnée
                            required
                          >
                            <option value="">Sélectionnez une famille</option>
                            {famillesProduits.map((famille) => (
                              <option
                                key={famille.id_famille_produit}
                                value={famille.nom_famille_produit}
                              >
                                {famille.nom_famille_produit}
                              </option>
                            ))}
                          </select>

                          <label htmlFor="famillesProduits">
                            Famille Produit
                          </label>
                        </div>
                      </div>

                      <div className="modal-footer d-flex justify-content-between align-items-center border-0">
                        <button
                          className="btn btn-primary px-4"
                          type="submit"
                          style={{
                            backgroundColor: "#00a600",
                          }}
                        >
                          {selectedcategorie
                            ? "Modifier categorie"
                            : "Ajouter categorie"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default CategorieProduits;
